import React, { Component } from 'react';

import Logo from '../images/logo.png';

import { Link, withRouter } from "react-router-dom";

import { Formik, Form, Field } from 'formik';

import api from '../services/Api';

import { login, userName } from "../services/Auth";

class Login extends Component {
    constructor(props) {
        super(props);

        this.handleLogin = this.handleLogin.bind(this);

        this.state = {
            username: "",
            password: "",
            error: "",
            class: "",
        };
    }


    handleLogin() {
        const { username, password } = this.state;
        if (!username || !password) {
            this.setState({ error: "Preencha e-mail e senha para continuar!" });
            this.setState({ class: "alert alert-warning text-center" });
        } else {
            api.post("/user/signin", { username, password }).then((response) => {
                if (!response.data.logged && !response.data.msg) {
                    this.setState({
                        error:
                            'E-mail ou Senha inválidos!'
                    });
                    this.setState({ class: "alert alert-danger text-center" });
                } else if (!response.data.logged && response.data.msg) {
                    this.setState({
                        error: response.data.msg
                    });
                    this.setState({ class: "alert alert-danger text-center" });
                } else if (response.data.token !== 'undefined' && response.data.token.length > 0) {
                    login(response.data.token);
                    userName(response.data.username);
                    this.props.history.push("/");
                }
            });
        }
    }

    render() {
        return (
            <>
                <div className="container-fluid h-100 login-component">
                    <div className="row h-100">
                        <div className="box my-auto mx-auto">
                            <div className="signin-body">
                                <div className="signin-header mb-4 text-center">
                                    <img src={Logo} alt="RCI Construções e Incorporações" />
                                </div>
                                <h2 className="text-center">Seja Bem-Vindo!</h2>
                                {this.state.error && <div className={this.state.class}>{this.state.error}</div>}
                                <Formik initialValues={{}} onSubmit={this.handleLogin}>
                                    <Form acceptCharset="utf-8" className="form-vertical">
                                        <div className="form-group has-feedback mb-3">
                                            <Field className="form-control" name="username" type="email" placeholder="E-mail" onChange={e => this.setState({ username: e.target.value })} required />
                                            <span className="form-control-feedback">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" className="align-middle">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="form-group has-feedback mb-3">
                                            <Field className="form-control" required="" id="password" minLength="6" type="password" name="password"
                                                placeholder="Senha" onChange={e => this.setState({ password: e.target.value })} />
                                            <span className="form-control-feedback">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round" className="align-middle">
                                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="checkbox mt-0">
                                                    <Field id="rememberme" type="checkbox" name="rememberme" />
                                                    &nbsp;<label htmlFor="rememberme">Lembre de mim</label>
                                                </div>
                                            </div>
                                            <div className="col-6 text-right">
                                                <Link to="/reset" className="signin-link">Esqueceu a senha?</Link>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn signin-btn btn-round btn-block mt-4 mb-4">Entrar</button>
                                        <p className="text-center mb-0">Não tem uma conta ainda?
                                            &nbsp;<Link to="/register" className="signin-link">Cadastre-se</Link>
                                        </p>
                                    </Form>
                                </Formik>
                            </div>
                            <div className="signin-footer text-center mt-3 mb-3">
                                <h3 className="mb-3"><span className="login">Redes Sociais</span></h3>
                                <a href="https://www.facebook.com/rcigrupo" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="align-middle">
                                        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/rcigrupo" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="align-middle">
                                        <path
                                            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                        </path>
                                    </svg>
                                </a>
                                <a href="https://www.instagram.com/rcigrupo/" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="align-middle">
                                        <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                    </svg>
                                </a>
                            </div>
                            <div className="mb-2 contact text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                    className="align-middle mr-2">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                                <span className="align-middle">(85) 3036-1010</span>
                            </div>
                            <div className="mb-2 contact text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                    className="align-middle mr-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg>
                                <span className="align-middle">Av. Dom Luís, 1200. Torre I - Aldeota</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stripes-wrapper">
                    <div className="stripes">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Login);