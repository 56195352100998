export const TOKEN_KEY = "@rci-token";
export const USERNAME = "@rci-user";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = () => localStorage.getItem(USERNAME);
export const login = token => {
    localStorage.setItem(TOKEN_KEY, token);
};
export const userName = user => {
    localStorage.setItem(USERNAME, user);
}
export const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
};