import React from 'react';

import Routes from '../components/Routes';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

window.BASEURL = 'https://gruporci.com.br/wp-json/rci/v1/';

const App = () => <Routes />

export default App;
