import React, { Component } from 'react';

import Logo from '../images/logo.png';

import { Link, withRouter } from "react-router-dom";

import MaskedInput from 'react-maskedinput';

import { Formik, Form, Field } from 'formik';

import Recaptcha from 'react-recaptcha';

import api from '../services/Api';

class Reset extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.state = {
            isVerified: false,
            error: null,
            success: null,
        }
    }

    handleSubmit(values) {
        if (this.state.isVerified) {
            api.post('user/reset', values).then((response) => {
                if (!response.data?.status) {
                    this.setState({
                        error: response.data?.msg,
                    });
                    return;
                }

                if (response.data?.status) {
                    this.setState({
                        error: null,
                    });

                    this.setState({
                        success: response.data?.msg,
                    });
                }
            });
        } else {
            this.setState({
                error: "Por favor efetue a verificação do Google reCAPTCHA",
            });
        }
    }

    verifyCallback(response) {
        if (response) {
            this.setState({
                isVerified: true
            });
        }
    }

    render() {
        return (
            <>
                <div className="container h-100 reset-component">
                    <div className="row h-100">
                        <div className="box my-auto mx-auto">
                            <div className="signin-body">
                                <div className="signin-header mb-4 text-center">
                                    <img src={Logo} alt="RCI Construções e Incorporações" />
                                </div>
                                {this.state.error && <div className="alert alert-danger text-center">{this.state.error}</div>}
                                {this.state.success && <div className="alert alert-success text-center">{this.state.success}</div>}
                                <Formik initialValues={{
                                    email: ''
                                }} onSubmit={this.handleSubmit}>
                                    {({ setFieldValue, handleChange }) => (
                                        <Form acceptCharset="utf-8">
                                            <h4 className="text-center mb-3">Redefinir Senha</h4>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label for="email">E-mail <span className="text-danger">*</span></label>
                                                    <Field name="email" className="form-control" id="email" type="email" required />
                                                </div>

                                            </div>

                                            <div className="form-row">
                                                <div className="col-12 text-center">
                                                    <Recaptcha
                                                        sitekey="6LfJjt0ZAAAAAMcYbhd5_y1hS6yNCw41_M3Z1RkE"
                                                        render="explicit"
                                                        verifyCallback={this.verifyCallback}
                                                    />
                                                    <button type="submit" className="btn btn-success mr-2">Salvar</button>

                                                    <Link to="/login" className="btn btn-dark">Voltar</Link>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stripes-wrapper">
                    <div className="stripes">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Reset);