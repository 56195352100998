import React, { Component } from 'react';

import Logo from '../images/logo.png';

import { Link, withRouter } from "react-router-dom";

import MaskedInput from 'react-maskedinput';

import { Formik, Form, Field } from 'formik';

import Recaptcha from 'react-recaptcha';

import api from '../services/Api';

class Register extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.searchCep = this.searchCep.bind(this);

        this.state = {
            isVerified: false,
            error: null,
            success: null,
        }
    }

    handleSubmit(values) {
        if (this.state.isVerified) {
            api.post('user/create', values ).then((response) => {
                if (!response.data?.status) {
                    this.setState({
                        error: response.data?.msg,
                    });
                    return;
                }

                if(response.data?.status){
                    this.setState({
                        success: response.data?.msg,
                    });
                }
            });
        } else {
            this.setState({
                error: "Por favor efetue a verificação do Google reCAPTCHA",
            });
        }
    }

    verifyCallback(response) {
        if (response) {
            this.setState({
                isVerified: true
            });
        }
    }

    searchCep(ev, setFieldValues) {
        const { value } = ev.target;

        const cep = value?.replace(/[^0-9]/g, '');

        if (cep?.length !== 8) {
            return;
        }

        api.get(`https://viacep.com.br/ws/${cep}/json/`).then((response) => {
            setFieldValues('address', response.data.logradouro);
            setFieldValues('district', response.data.bairro);
            setFieldValues('state', response.data.uf);
            setFieldValues('city', response.data.localidade);
        });
    }

    render() {
        return (
            <>
                <div className="container h-100 register-component">
                    <div className="row h-100">
                        <div className="box my-auto mx-auto">
                            <div className="signin-body">
                                <div className="signin-header mb-4 text-center">
                                    <img src={Logo} alt="RCI Construções e Incorporações" />
                                </div>
                                {this.state.error && <div className="alert alert-danger text-center">{this.state.error}</div>}
                                {this.state.success && <div className="alert alert-success text-center">{this.state.success}</div>}
                                <Formik initialValues={{
                                    name: '',
                                    cpf: '',
                                    email: '',
                                    birth: '',
                                    businnes: '',
                                    creci: '',
                                    phone: '',
                                    mobile: '',
                                    password: '',
                                    cep: '',
                                    address: '',
                                    complement: '',
                                    district: '',
                                    state: '',
                                    city: '',

                                }} onSubmit={this.handleSubmit}>
                                    {({ setFieldValue, handleChange }) => (
                                        <Form acceptCharset="utf-8">
                                            <h4 className="text-center mb-3">Usuário</h4>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="name">Nome Completo <span className="text-danger">*</span></label>
                                                    <Field name="name" className="form-control" id="name" required />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="cpf">CPF</label>
                                                    <MaskedInput name="cpf" className="form-control" id="cpf" mask="111.111.111-11" onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="email">E-mail <span className="text-danger">*</span></label>
                                                    <Field name="email" className="form-control" id="email" type="email" required />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="birth">Data de Nascimento</label>
                                                    <MaskedInput mask="11/11/1111" name="birth" className="form-control" id="birth" onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="businnes">Imobiliária</label>
                                                    <Field name="businnes" className="form-control" type="text" id="businnes" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="creci">Creci</label>
                                                    <Field name="creci" className="form-control" type="text" id="creci" />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="phone">Telefone <span className="text-danger">*</span></label>
                                                    <MaskedInput {...this.props} name='phone' className="form-control" mask="(11) 1111-1111" onChange={handleChange} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="mobile">Celular</label>
                                                    <MaskedInput mask="(11) 11111-1111" name="mobile" className="form-control" id="mobile" onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="phone">Senha <span className="text-danger">*</span></label>
                                                    <Field minLength="6" name="password" className="form-control" type="password" id="password" required />
                                                </div>
                                            </div>
                                            <h4 className="text-center mb-3">Endereço</h4>
                                            <div className="form-row">
                                                <div className="form-group col-md-3">
                                                    <label for="cep">CEP</label>
                                                    <MaskedInput mask="11111-111" name="cep" onBlur={(ev) => { this.searchCep(ev, setFieldValue) }} className="form-control" id="cep" onChange={handleChange} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="address">Endereço</label>
                                                    <Field name="address" className="form-control" type="text" id="address" />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label for="address_number">Número</label>
                                                    <Field name="address_number" className="form-control" type="text" id="address_number" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="complement">Complemento</label>
                                                    <Field name="complement" className="form-control" type="text" id="complement" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="district">Bairro</label>
                                                    <Field name="district" className="form-control" type="text" id="district" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label for="state">Estado</label>
                                                    <Field name="state" className="form-control" type="text" id="state" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="city">Cidade</label>
                                                    <Field name="city" className="form-control" type="text" id="city" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-12 text-center">
                                                    <Recaptcha
                                                        sitekey="6LfJjt0ZAAAAAMcYbhd5_y1hS6yNCw41_M3Z1RkE"
                                                        render="explicit"
                                                        verifyCallback={this.verifyCallback}
                                                    />
                                                    <button type="submit" className="btn btn-success mr-2">Salvar</button>

                                                    <Link to="/login" className="btn btn-dark">Voltar</Link>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stripes-wrapper">
                    <div className="stripes">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Register);