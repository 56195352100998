import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router';

import { isAuthenticated } from "../services/Auth";

import Login from '../pages/Login';
import Register from '../pages/Register';
import Home from '../pages/Home';
import View from '../pages/View';
import Reset from '../pages/Reset';
import NotFound from './NotFound';

import { history } from '../history';
import Password from '../pages/Password';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
        }
    />
);

const Routes = () => (
    <Router history={history}>
        <Switch>
            <Route component={Login} exact path="/login" />
            <Route component={Register} exact path="/register" />
            <PrivateRoute component={Home} exact path="/" />
            <PrivateRoute component={View} exact path="/view/:id?" />
            <Route component={Reset} exact path="/reset" />
            <Route component={Password} exact path="/reset/:id?" />
            <PrivateRoute component={NotFound} />
        </Switch>
    </Router>
);

export default Routes;