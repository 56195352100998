import React, { Component } from 'react';

import Logo from '../images/logo-circle.png';
import thumbnail from '../images/default_thumbnail.jpg';
import avatar from '../images/default_avatar.png';

import { Link, withRouter } from "react-router-dom";
import { Form, Formik } from 'formik';
import { OverlayTrigger, Spinner } from 'react-bootstrap';
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from 'react-bootstrap/Dropdown';

import api from '../services/Api';

import { getToken, getUser, logout } from '../services/Auth';




class Home extends Component {

    state = {
        search: "",
        category: "",
        error: "",
        loading: false,
        enterprises: []
    };

    componentDidMount() {
        try {
            let query = new URLSearchParams(this.props.location.search);
            this.setState({ loading: true }, () => {
                api.get("/enterprise/list", { params: { "jwt": getToken(), 'status': query.get('category') } }).then(response => this.setState({
                    enterprises: response.data.enterprises,
                    loading: false
                }));
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    handleSearch = async (e) => {
        const { search, category } = this.state;

        try {
            this.setState({ loading: true }, () => {
                api.get("/enterprise/list", { params: { "jwt": getToken(), "status": category, 'search': search } }).then(response => this.setState({
                    enterprises: response.data.enterprises,
                    loading: false
                }));
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    resetSearch = async (e) => {
        try {
            this.setState({ isloading: true });
            this.props.history.push("/");

            this.setState({ loading: true }, () => {
                api.get("/enterprise/list", { params: { "jwt": getToken() } }).then(response => this.setState({
                    enterprises: response.data.enterprises,
                    loading: false
                }));
            });
        } catch (err) {
            this.setState({ error: err.message });
        }
    }

    renderImageThumbnail(param) {
        if (param == null) {
            return thumbnail;
        } else {
            return param;
        }
    }

    renderImageLogo(param) {
        if (param == null) {
            return avatar;
        } else {
            return param;
        }
    }

    renderSwitchStatus(param) {
        switch (param) {
            case "1":
                return <span className="badge badge-primary p-2">À Venda</span>;
            case "2":
                return <span className="badge badge-warning p-2">Em Breve</span>;
            case "3":
                return <span className="badge badge-danger p-2">Em Construção</span>;
            case "4":
                return <span className="badge badge-success p-2">Entregue</span>;
            case "5":
                return <span className="badge badge-secondary p-2">Lançamento</span>;
            case "6":
                return <span className="badge badge-info p-2">Pré-Lançamento</span>;
            case "7":
                return <span className="badge badge-primary p-2">Proto</span>;
            case "8":
                return <span className="badge badge-success p-2">Concluído</span>;
            default:
                return <span className="badge badge-danger p-2">Em Construção</span>;
        }
    }



    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark shadow-sm">
                    <a className="navbar-brand" href="#">
                        <img src={Logo} className="rounded-circle" height="50" width="50" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navigation"
                        aria-controls="main-navigation" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="main-navigation">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item active">
                                <Link className="nav-link font-weight-bold pr-3 pl-3" to="/">Empreendimentos<span
                                    className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link font-weight-bold pr-3 pl-3" href="#" onClick={logout}>Sair</a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <main className="main-wrapper">
                    <div className="main">
                        <div className="filter box-shadow border-radius-3 d-flex justify-content-between">
                            <div class="filter-welcome d-none d-md-block">
                                Olá <strong>{getUser()}</strong>, seja bem vindo!
                            </div>
                            <div class="filter-toolbar d-md-flex justify-content-end">
                                <div className="filter-status">
                                    <Dropdown className="d-none d-md-block">
                                        <Dropdown.Toggle variant="secondary">
                                            Categorias
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/">Todos</Dropdown.Item>
                                            <Dropdown.Item href="?category=1">À Venda</Dropdown.Item>
                                            <Dropdown.Item href="?category=8">Concluído</Dropdown.Item>
                                            <Dropdown.Item href="?category=2">Em Breve</Dropdown.Item>
                                            <Dropdown.Item href="?category=3">Em Construção</Dropdown.Item>
                                            <Dropdown.Item href="?category=4">Entregue</Dropdown.Item>
                                            <Dropdown.Item href="?category=5">Lançamento</Dropdown.Item>
                                            <Dropdown.Item href="?category=6">Pré-Lançamento</Dropdown.Item>
                                            <Dropdown.Item href="?category=7">Pronto(a)</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="search-wrapper">
                                    <Formik initialValues={{}} onSubmit={this.handleSearch}>
                                        <Form method="GET" className="form-inline">
                                            <input type="search" className="form-control filter-input" name="search" placeholder="Buscar..."
                                                required onChange={e => this.setState({ search: e.target.value })} />
                                            <button type="submit" className="btn btn-search ml-2">Buscar</button>
                                            <button type="button" onClick={this.resetSearch} className="btn btn-dark ml-2">Redefinir</button>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="enterprises-wrapper pt-2 pb-2">
                            {this.state.loading && this.state.enterprises.length == 0 ? (
                                <div className="row h-100">
                                    <div className="col-12 h-100 text-center p-3">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Carregando...</span>
                                        </Spinner>
                                    </div>
                                </div>
                            ) :
                                this.state.enterprises.length > 0 && !this.state.loading ? (
                                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xs-4">
                                        {this.state.enterprises.map(e => (
                                            <Link to={`/view/${e.id}`} className="text-decoration-none">
                                                <div className="col">
                                                    <div className="card box-shadow border-radius-3">

                                                        <div className="card-up" style={{ "backgroundImage": `url(${this.renderImageThumbnail(e.thumbnail)})` }}></div>

                                                        <div className="avatar mx-auto white rounded-circle"
                                                            style={{ "backgroundImage": `url(${this.renderImageLogo(e.logo)})` }}>
                                                        </div>

                                                        <div className="card-body text-center">
                                                            <div className="text-center">
                                                                <span className="card-title">
                                                                    {e.name}
                                                                </span>
                                                            </div>
                                                            <div className="text-center mt-4 mb-4">
                                                                {this.renderSwitchStatus(e.status)}
                                                            </div>
                                                            <div className="card-info text-center mt-3">
                                                                {e.private_area !== "" && (
                                                                    <OverlayTrigger overlay={
                                                                        <Tooltip>
                                                                            {e.private_area} de área privativa
                                                                        </Tooltip>
                                                                    }>
                                                                        <div className="card-info__item">
                                                                            <span className="card-icon">
                                                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill-rule="nonzero" fill="none">
                                                                                        <path d="M0 0h24v24H0z"></path>
                                                                                        <path d="M2 2h20v20H2z"></path>
                                                                                        <path
                                                                                            d="M5 18V6a1 1 0 00-2 0v13a2 2 0 002 2h13a1 1 0 000-2H6a1 1 0 01-1-1zM19.25 3c.966 0 1.75.784 1.75 1.75v10.5A1.75 1.75 0 0119.25 17H8.75A1.75 1.75 0 017 15.25V4.75C7 3.784 7.784 3 8.75 3zM18 5h-8a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1z"
                                                                                            fill="currentColor"></path>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                )}
                                                                {e.bedrooms !== "" && (
                                                                    <OverlayTrigger overlay={
                                                                        <Tooltip>
                                                                            {e.bedrooms} quartos
                                                                        </Tooltip>
                                                                    }>
                                                                        <div className="card-info__item">
                                                                            <span className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path fill="currentColor"
                                                                                        d="M21 14H3v-1c0-.801.312-1.555.879-2.121A2.978 2.978 0 016 10h12c.801 0 1.555.312 2.121.879.567.566.879 1.32.879 2.121v1zm0 3H3v-1h18v1zM5 8V6c0-.551.449-1 1-1h4c.551 0 1 .449 1 1v2H5zm8-2c0-.551.449-1 1-1h4c.551 0 1 .449 1 1v2h-6V6zm8 3V6a3 3 0 00-3-3h-4c-.768 0-1.47.29-2 .764A2.993 2.993 0 0010 3H6a3 3 0 00-3 3v3a4.992 4.992 0 00-2 4v6a2 2 0 002 2h1v-2h16v2h1a2 2 0 002-2v-6a4.992 4.992 0 00-2-4z">
                                                                                    </path>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                )}
                                                                {e.garage !== "" && (
                                                                    <OverlayTrigger overlay={
                                                                        <Tooltip>
                                                                            {e.garage} garagens
                                                                        </Tooltip>
                                                                    }>
                                                                        <div className="card-info__item">
                                                                            <span className="card-icon">
                                                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill-rule="nonzero" fill="none">
                                                                                        <path d="M0 0h24v24H0z"></path>
                                                                                        <path d="M2 2h20v20H2z"></path>
                                                                                        <path
                                                                                            d="M15.843 2a3 3 0 012.935 2.38l.778 3.671A3 3 0 0122 11v9a2 2 0 01-2 2h-1a1 1 0 01-1-1v-2H6v2a1 1 0 01-1 1H4a2 2 0 01-2-2v-9a3 3 0 012.444-2.949l.778-3.672A3 3 0 018.157 2zM19 10H5a1 1 0 00-1 1v6h16v-6a1 1 0 00-1-1zm-2.5 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-9 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm8.343-8H8.157l-.117.007a1 1 0 00-.862.786L6.5 8h11l-.678-3.207-.031-.113a1 1 0 00-.948-.68z"
                                                                                            fill="currentColor"></path>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                )}
                                                                {e.suites !== "" && (
                                                                    <OverlayTrigger overlay={
                                                                        <Tooltip>
                                                                            {e.suites} banheiros
                                                                        </Tooltip>
                                                                    }>
                                                                        <div className="card-info__item">
                                                                            <span className="card-icon">
                                                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill-rule="nonzero" fill="none">
                                                                                        <path d="M0 0h24v24H0z"></path>
                                                                                        <path d="M2 2h20v20H2z"></path>
                                                                                        <path
                                                                                            d="M10.912 16h.125a.963.963 0 00.963-.963V13h-2v2.088c0 .504.408.912.912.912zm4 3h.125a.963.963 0 00.963-.963V13h-2v5.088c0 .504.408.912.912.912zm4-3h.125a.963.963 0 00.963-.963V13h-2v2.088c0 .504.408.912.912.912zM21 11H9V9a2 2 0 012-2h3V4H5v17a1 1 0 11-2 0V4a2 2 0 012-2h9a2 2 0 012 2v3h3a2 2 0 012 2v2z"
                                                                                            fill="currentColor"></path>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                )}
                                                                {e.objective !== "" && e.objective == 'comercial' && (
                                                                    <OverlayTrigger overlay={
                                                                        <Tooltip>
                                                                            {e.objective}
                                                                        </Tooltip>
                                                                    }>
                                                                        <div className="card-info__item">
                                                                            <span className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                                                    stroke-linejoin="round">
                                                                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                                                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                )}
                                                            </div>
                                                            <div className="text-center mt-3">
                                                                <span className="icon-marker-map align-middle">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                                        stroke-linejoin="round" className="align-middle mr-2">
                                                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                                        <circle cx="12" cy="10" r="3"></circle>
                                                                    </svg>
                                                                </span>
                                                                <span className="align-middle text-map">{e.district}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card box-shadow border-radius-3 text-center p-3">
                                                    Nenhum emprendimento encontrado!
                                            </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Home);