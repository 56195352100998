import React, { Component } from 'react';

import Logo from '../images/logo-circle.png';
import thumbnail from '../images/default_thumbnail.jpg';
import avatar from '../images/default_avatar.png';

import { Link, withRouter } from "react-router-dom";
import { Spinner, Table } from 'react-bootstrap';

import api from '../services/Api';

import { getToken, getUser, logout } from '../services/Auth';




class View extends Component {

    state = {
        isloading: false,
        enterprise: []
    };

    async componentDidMount() {
        if (typeof this.props.match.params.id != 'undefined') {
            try {
                this.setState({ isloading: true });
                let response = await api.get("/enterprise/view/" + this.props.match.params.id, { params: { "jwt": getToken() } });
                this.setState({
                    enterprise: response.data.enterprise
                });
                this.setState({ isloading: false });
            } catch (err) {
                this.setState({ error: err.message });
            }
        } else {
            this.props.history.push("/");
        }
    }

    renderSwitchStatus(param) {
        switch (param) {
            case "1":
                return <span className="badge badge-primary p-2">À Venda</span>;
            case "2":
                return <span className="badge badge-warning p-2">Em Breve</span>;
            case "3":
                return <span className="badge badge-danger p-2">Em Construção</span>;
            case "4":
                return <span className="badge badge-success p-2">Entregue</span>;
            case "5":
                return <span className="badge badge-secondary p-2">Lançamento</span>;
            case "6":
                return <span className="badge badge-info p-2">Pré-Lançamento</span>;
            case "7":
                return <span className="badge badge-primary p-2">Proto</span>;
            case "8":
                return <span className="badge badge-success p-2">Concluído</span>;
            default:
                return <span className="badge badge-danger p-2">Em Construção</span>;
        }
    }

    renderImageThumbnail(param) {
        if (param == null) {
            return thumbnail;
        } else {
            return param;
        }
    }

    renderImageLogo(param) {
        if (param == null) {
            return avatar;
        } else {
            return param;
        }
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow-sm">
                    <a className="navbar-brand" href="#">
                        <img src={Logo} className="rounded-circle" height="50" width="50" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navigation"
                        aria-controls="main-navigation" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="main-navigation">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item active">
                                <Link className="nav-link font-weight-bold pr-3 pl-3" to="/">Empreendimentos<span
                                    className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link font-weight-bold pr-3 pl-3" to="#" onClick={logout}>Sair</Link>
                            </li>
                        </ul>
                    </div>
                </nav>

                <header className="header">
                    <div className="header-cover" style={{ "backgroundImage": `url('${this.renderImageThumbnail(this.state.enterprise.thumbnail)}')` }}>
                        <div className="container h-100">
                            <div className="row h-100 d-md-flex justify-md-content-between align-items-center">
                                <div
                                    className="col-12 col-md-6 col-lg-6 header-title text-center text-md-left d-md-flex align-items-center">
                                    <div className="header-avatar"
                                        style={{ "backgroundImage": `url('${this.renderImageLogo(this.state.enterprise.logo)}')` }}>
                                    </div>
                                    <div className="title-container ml-md-4 mt-3 mt-md-0">
                                        <h3>{this.state.enterprise.name}</h3>
                                        {this.renderSwitchStatus(this.state.enterprise.status)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container pt-5 pb-4">
                    <div className="row">
                        {this.state.enterprise.plan && (
                            <div className="col-6 col-md-3 p-3">

                                <a href={this.state.enterprise.plan} target="_blank" download className="text-decoration-none">
                                    <div class="view-button">
                                        <svg height="30" width="30" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path d="m58 16h-14v4h1v12h2v-2h11v12h-19v2h1.941c-.463 4.165-3.776 7.478-7.941 7.941v-8.941h-2v15h-11v-25h16v-2h-16v-2h4c.553 0 1-.447 1-1v-8h5v-4h-14v46h46v-4-42zm-38 4h3v7h-3zm38 38h-25v-4.051c5.268-.478 9.472-4.681 9.949-9.949h15.051zm0-30h-11v-8h11z" />
                                                <path d="m6.293 12.293c.391-.391 1.023-.391 1.414 0l1.293 1.293 1.783-1.783-.901-1.803h-5.764l-.901 1.803 1.783 1.783z" />
                                                <path d="m7 61c2.206 0 4-1.794 4-4h-8c0 2.206 1.794 4 4 4z" />
                                                <path d="m7 4.236-1.882 3.764h3.764z" />
                                                <path d="m9 16c-.256 0-.512-.098-.707-.293l-1.293-1.293-1.293 1.293c-.391.391-1.023.391-1.414 0l-1.293-1.293v36.586h8v-36.586l-1.293 1.293c-.195.195-.451.293-.707.293z" />
                                                <path d="m3 53h8v2h-8z" />
                                            </g>
                                        </svg>
                                        <span className="view-button-label mt-3">
                                            Plantas
                                        </span>
                                    </div>
                                </a>
                            </div>
                        )}
                        {this.state.enterprise.folders && (
                            <div className="col-6 col-md-3 p-3">
                                <a href={this.state.enterprise.folders} target="_blank" download className="text-decoration-none">
                                    <div class="view-button">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <g>
                                                <g>
                                                    <path d="M362.656,42.664V0L128,42.664V512h256V42.664H362.656z M341.328,25.562v17.102H247.25L341.328,25.562z M362.656,490.656 H149.312V64h213.344V490.656z" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M276.313,163.555l-37.281,65.211l-19.625-32.703l-48.75,81.281h40.625h56.906h73.125L276.313,163.555z M223.469,256 h-15.125l11.062-18.469l7.5,12.477L223.469,256z M248.062,256l28.25-49.445L304.562,256H248.062z" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M288,384h-64c-5.906,0-10.688,4.781-10.688,10.656c0,5.906,4.781,10.688,10.688,10.688h64 c5.875,0,10.656-4.781,10.656-10.688C298.656,388.781,293.875,384,288,384z" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M330.656,341.344H181.312c-5.875,0-10.656,4.766-10.656,10.656s4.782,10.656,10.656,10.656h149.344 c5.891,0,10.656-4.766,10.656-10.656S336.546,341.344,330.656,341.344z" />
                                                </g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                        </svg>
                                        <span className="view-button-label mt-3">
                                            Folders
                                        </span>
                                    </div>
                                </a>

                            </div>
                        )}
                        {this.state.enterprise.images && (
                            <div className="col-6 col-md-3 p-3">
                                <a href={this.state.enterprise.images} target="_blank" download className="text-decoration-none">
                                    <div class="view-button">
                                        <svg height="511pt" viewBox="0 -21 511.98744 511" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m133.320312 373.828125c-34.152343 0-64.53125-21.867187-75.5625-54.421875l-.746093-2.453125c-2.601563-8.621094-3.691407-15.871094-3.691407-23.125v-145.453125l-51.753906 172.757812c-6.65625 25.410157 8.511719 51.753907 33.960938 58.773438l329.878906 88.34375c4.117188 1.066406 8.234375 1.578125 12.289062 1.578125 21.246094 0 40.660157-14.101563 46.101563-34.882813l19.21875-61.117187zm0 0" />
                                            <path d="m191.988281 149.828125c23.53125 0 42.664063-19.136719 42.664063-42.667969s-19.132813-42.667968-42.664063-42.667968-42.667969 19.136718-42.667969 42.667968 19.136719 42.667969 42.667969 42.667969zm0 0" />
                                            <path d="m458.652344.492188h-320c-29.394532 0-53.332032 23.9375-53.332032 53.335937v234.664063c0 29.398437 23.9375 53.335937 53.332032 53.335937h320c29.398437 0 53.335937-23.9375 53.335937-53.335937v-234.664063c0-29.398437-23.9375-53.335937-53.335937-53.335937zm-320 42.667968h320c5.890625 0 10.667968 4.777344 10.667968 10.667969v151.445313l-67.390624-78.636719c-7.148438-8.382813-17.496094-12.863281-28.609376-13.117188-11.050781.0625-21.417968 4.96875-28.5 13.460938l-79.234374 95.101562-25.8125-25.75c-14.589844-14.589843-38.335938-14.589843-52.90625 0l-58.878907 58.859375v-201.363281c0-5.890625 4.777344-10.667969 10.664063-10.667969zm0 0" />
                                        </svg>
                                        <span className="view-button-label mt-3">
                                            Imagens
                                </span>
                                    </div>
                                </a>
                            </div>
                        )}
                        {this.state.enterprise.table_price && (
                            <div className="col-6 col-md-3 p-3 ">
                                <a href={this.state.enterprise.table_price} target="_blank" download className="text-decoration-none">
                                    <div class="view-button">
                                        <svg height="64" viewBox="0 0 512 512" width="64" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path d="m256 512c-68.38 0-132.668-26.628-181.02-74.98s-74.98-112.64-74.98-181.02 26.629-132.667 74.98-181.02 112.64-74.98 181.02-74.98 132.668 26.628 181.02 74.98 74.98 112.64 74.98 181.02-26.629 132.667-74.98 181.02-112.64 74.98-181.02 74.98zm0-480c-123.514 0-224 100.486-224 224s100.486 224 224 224 224-100.486 224-224-100.486-224-224-224z" />
                                                <path d="m256 240c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40c0 8.836 7.163 16 16 16s16-7.164 16-16c0-34.201-23.978-62.888-56-70.186v-17.814c0-8.836-7.163-16-16-16s-16 7.164-16 16v17.814c-32.022 7.298-56 35.985-56 70.186 0 39.701 32.299 72 72 72 22.056 0 40 17.944 40 40s-17.944 40-40 40-40-17.944-40-40c0-8.836-7.163-16-16-16s-16 7.164-16 16c0 34.201 23.978 62.888 56 70.186v17.814c0 8.836 7.163 16 16 16s16-7.164 16-16v-17.814c32.022-7.298 56-35.985 56-70.186 0-39.701-32.299-72-72-72z" />
                                            </g>
                                        </svg>
                                        <span className="view-button-label mt-3">
                                            Preços e Disponibilidades
                                </span>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(View);